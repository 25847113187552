import React from 'react'
import { Box, Heading } from 'rebass'

const Pagetitle = ({ children }) => {
  return (
    <>
      <Box p={5} bg="#D9E8ED">
        <Heading
          fontSize={[5, 5, 6]}
          fontWeight="500"
          textAlign="center"
          fontFamily="Playfair Display, serif"
        >
          {children}
        </Heading>
      </Box>
    </>
  )
}

export default Pagetitle
