import styled from 'styled-components'

const Content = styled.div`
  max-width: 960px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 140%;

  a {
    color: #f06449;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 1.4em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }

  p {
    margin-bottom: 16px;
  }

  b,
  strong {
    font-weight: 600;
  }

  ul,
  ol {
    margin: 16px 0;
    margin-left: 16px;
  }

  li {
    list-style: circle;
    margin-bottom: 8px;
  }

  ol {
    li {
      list-style: lower-alpha;
    }
  }
`

export default Content
