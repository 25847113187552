import React from 'react'
import { Box } from 'rebass'

const Container = props => (
  <Box
    {...props}
    mx="auto"
    p={[3, 3, 3]}
    css={{
      maxWidth: '1224px',
    }}
  >
    {props.children}
  </Box>
)

export default Container
